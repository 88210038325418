<script setup>

import { onMounted, reactive, ref } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useCourseSessionStore } from "@/store/courses/course-session"
import { useToast } from "primevue/usetoast"

import EditorCustom from '../../../../modules/ckeditor/EditorCustom.vue'

const route = useRoute()
const router = useRouter()
const toast = useToast()

const groupCourseSessionStore = useCourseSessionStore()
const courseSession = reactive({
  editor_main: null,
})

const creating = ref(false)

const loading = ref(false)
const loadingEditor = ref(false)
const uploadDisabled = ref(false)

let oldPicture = {
  picture: null,
  picture_main: null
}

const save = () => {
  const action = creating.value
    ? groupCourseSessionStore.store({ course_id: route.params.courseId, title: courseSession.title })
    : groupCourseSessionStore.update(route.params.id, courseSession)
  loading.value = true
  action.then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      loading.value = false
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

    oldPicture['picture'] = data.course_session.picture_url
    oldPicture['picture_main'] = data.course_session.picture_url_main

    if (creating.value) {
      setTimeout(() => {
        Object.keys(data.course_session).forEach((key) => {
          courseSession[key] = data.course_session[key]
        })

        courseSession['editor_main'] = ''
        
        router.replace({ name: 'courses.sessions.edit', params: { id: data.course_session.id } })
        creating.value = false
        loading.value = false
      }, 1000)

      return
    } else {
      courseSession.updated_at = data.course_session.updated_at
    }

    loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

const uploader = (name, event) => {
  uploadDisabled.value = true
  const reader = new FileReader()
  reader.readAsDataURL(event.files[event.files.length - 1])
  reader.onload = function () {
    courseSession[name] = reader.result
    uploadDisabled.value = false
  }
  reader.onerror = function () {
    uploadDisabled.value = false
  }
}

const uploadRemove = (name) => {
  if (oldPicture[name] !== null) courseSession[name] = oldPicture[name]
  else delete courseSession[name]
}

onMounted(() => {
  creating.value = (typeof route.params.id === 'undefined' && typeof courseSession.id === 'undefined')

  loading.value = true
  if (typeof route.params.id !== 'undefined') {
    groupCourseSessionStore.show(route.params.id)
      .then((response) => {
        const _courseSession = response.data.course_session

        Object.keys(_courseSession).forEach((key) => {
          courseSession[key] = _courseSession[key]
        })

        courseSession['editor_main'] = _courseSession.editor_main || ''

        courseSession['picture'] = courseSession['picture_url']
        oldPicture['picture'] = courseSession['picture_url']

        courseSession['picture_main'] = courseSession['picture_url_main']
        oldPicture['picture_main'] = courseSession['picture_url_main']

        loading.value = false
      })
      .catch(() => loading.value = false)
  } else {
    courseSession.type = 'text'

    loading.value = false
  }
})

</script>
<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="courseSession.id" class="block-title">
            <div>Edit #{{ courseSession.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>

    <div class="block-content">
      <div class="grid">
        <div class="col-12">
          <div class="card">

            <div class="px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
              <div class="p-4 border-round w-full lg:w-6">
                <div>
                  <label class="block text-900 font-medium mb-2" for="title">Title</label>
                  <InputText v-model="courseSession.title" id="title" type="text" class="w-full mb-3" />

                  <div v-if="! creating">
                    <label class="block text-900 font-medium mb-2" for="sub_title">Sub Title</label>
                    <InputText v-model="courseSession.sub_title" id="sub_title" type="text" class="w-full mb-3" />

                    <label class="block text-900 font-medium mb-2" for="description">Description</label>
                    <InputText v-model="courseSession.description" id="description" type="text" class="w-full mb-3" />
                    
                    <label class="block text-900 font-medium mb-2" for="picture_url">Picture</label>
                    <Image v-if="courseSession.picture" :src="courseSession.picture" preview alt="Image Text" class="mb-3" style="width: 100%"/>
                    <FileUpload
                      accept="image/*"
                      :customUpload="true"
                      :auto="true"
                      :showUploadButton="false"
                      :disabled="uploadDisabled"
                      @uploader="uploader('picture', $event)"
                      @clear="uploadRemove('picture')"
                    />

                    <divider />

                    <label class="block text-900 font-medium mb-2" for="picture_main">Picture Main</label>
                    <Image v-if="courseSession.picture_main" :src="courseSession.picture_main" preview alt="Image Text" class="mb-3" style="width: 100%"/>
                    <FileUpload
                      accept="image/*"
                      :customUpload="true"
                      :auto="true"
                      :showUploadButton="false"
                      :disabled="uploadDisabled"
                      @uploader="uploader('picture_main', $event)"
                      @clear="uploadRemove('picture_main')"
                    />
                  </div>
                  
                </div>
              </div>
            </div>

            <div class="flex justify-content-center">
              <div class="col-12 md:col-11 shadow-2">
                <editor-custom
                  v-if="courseSession.editor_main !== null"
                  v-model="courseSession.editor_main"

                  :disabled="loadingEditor"
                  :path="`courses/${courseSession.course_id}`"
                />
              </div>
            </div>

            <div class="px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
              <div class="p-4 border-round w-full lg:w-6">
                <div>
                  <div v-if="! creating">
                    <div class="mb-6" />
                    
                    <label class="block text-900 font-medium mb-2" for="created_at">Created</label>
                    <div class="mb-3">{{ courseSession.created_at ? new Date(courseSession.created_at).toLocaleString() : '-' }}</div>

                    <label class="block text-900 font-medium mb-2" for="updated_at">Updated</label>
                    <div class="mb-3">{{ courseSession.updated_at ? new Date(courseSession.updated_at).toLocaleString() : '-' }}</div>
                  </div>
                </div>

                <br/>

                <Button label="Save" class="w-full" :loading="loading" @click="save()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.p-image img {
  width: 100%;
  border: 1px solid #dee2e6 !important;
}
.p-fileupload-buttonbar {
  border-bottom: 1px solid #dee2e6 !important;
  margin-bottom: 1rem;
}
.p-fileupload-content {
  display: none;
}
</style>

<style lang="scss" scoped>
#editor::v-deep {
  .ck.ck-content.ck-editor__editable { min-height: 1000px; }
}

</style>
