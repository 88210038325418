import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useCourseSessionStore = defineStore('courseSession', {
  actions: {
    destroy: (id) => axios.delete(`${VUE_APP_ENDPOINT_URL}/api/admin/course-sessions/${id}`),
		index: (event = {}, courseId) => {
      event.page = event.page ? event.page + 1 : 1
      event.sortField = event.sortField || 'ordering'
      event.sortOrder = event.sortOrder || 1

			return axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/course-sessions`, { 
        params: {
          course_id: courseId,
          ...event
        }
      })
		},
    ordering: (courseSessionIds) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/course-sessions/ordering`, courseSessionIds),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/course-sessions/${id}`),
    store: (courseSession) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/course-sessions`, courseSession),
		update: (id, courseSession) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/course-sessions/${id}`, courseSession),
  }
})
